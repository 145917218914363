import {ISantaProps} from 'native-components-infra/dist/src/types/types'
import {connect} from '../../runtime-context/connect'
import {Resizable as ResizablePresentation} from './resizable'

export interface ResizableOwnProps {
  [someProp: string]: any
  resizeFunc: (width: number, height: number) => void
}

export interface ResizableRuntimeProps {
  width: number
  height: number
}

export interface ResizableProps extends ResizableRuntimeProps, ResizableOwnProps {}

const mapRuntime = ({dimensions}: ISantaProps): ResizableRuntimeProps => ({
  width: dimensions.width,
  height: dimensions.height,
})

export const Resizable = connect<ResizableOwnProps, ResizableRuntimeProps>(mapRuntime)(ResizablePresentation)
