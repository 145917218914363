import {ExperimentsProvider} from '@wix/wix-experiments-react'
import * as React from 'react'
import {I18nextProvider} from 'react-i18next'
import {TPAComponentsProvider} from 'wix-ui-tpa/dist/src/components/TPAComponentsConfig'
import {RuntimeContext} from '../../../commons/components/runtime-context/runtime-context'
import {isMobile} from '../../../commons/selectors/environment'
import i18n from '../../../commons/services/i18n'
import {MemberAppProps} from '../../interface'
import {shouldLoadMore} from '../../selectors/events'
import {resizeHeight} from '../../services/resize'
import {AppContainer} from '../app-container'
import {AppLoaded} from '../app-loaded'
import {ErrorBoundary} from '../error-boundary'

const SCROLL_CUTOFF = 0.95

export default class AppRoot extends React.Component<MemberAppProps> {
  componentDidMount(): void {
    this.props.registerToScroll(({progressY}) => {
      if (progressY > SCROLL_CUTOFF && shouldLoadMore(this.props.state)) {
        this.props.actions.getEvents(this.props.state.tab)
      }
    })
  }

  resize = () => {
    resizeHeight(this.props.state)
  }

  render() {
    const {
      environment: {language},
      experiments,
    } = this.props.state
    const mobile = isMobile(this.props.state)

    return (
      <ErrorBoundary>
        <RuntimeContext.Provider value={this.props}>
          <TPAComponentsProvider value={{mobile}}>
            <I18nextProvider i18n={i18n(language, 'members-page')}>
              <ExperimentsProvider options={{experiments}}>
                <>
                  <AppLoaded host={this.props.host} />
                  <AppContainer resize={this.resize} isRTL={this.props.isRTL} />
                </>
              </ExperimentsProvider>
            </I18nextProvider>
          </TPAComponentsProvider>
        </RuntimeContext.Provider>
      </ErrorBoundary>
    )
  }
}
