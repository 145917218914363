import {handleKeyPressEnterOrSpace} from '@wix/wix-events-commons-statics/dist/accessibility'
import * as classNames from 'classnames'
import * as React from 'react'
import ChevronDown from 'wix-ui-icons-common/ChevronDown'
import s from './expand-icon.scss'

interface ExpandIconProps {
  expanded?: boolean
  right?: boolean
  onClick: () => void
  mobile?: boolean
  extraClass?: string
  href?: string
}

export const ExpandIcon = ({expanded, right, onClick, extraClass, mobile, href}: ExpandIconProps) => (
  <a
    href={href}
    onClick={href ? null : onClick}
    onKeyPress={href ? null : handleKeyPressEnterOrSpace(onClick)}
    tabIndex={0}
    className={s.root}
  >
    <ChevronDown
      size={mobile ? 14 : 16}
      viewBox="8 7 9 10"
      className={classNames(s.icon, {[s.up]: expanded, [s.right]: right}, extraClass ? extraClass : '')}
    />
  </a>
)
