import {isMobile, isSite} from '../../../commons/selectors/environment'
import {MembersTexts} from '../../../commons/types/members-settings'
import {navigateToPage} from '../../../commons/utils/wix-sdk'
import {getUserText} from '../../selectors/component'
import {getNoUpcomingEventsPageId, getNoUpcomingEventsUrl} from '../../selectors/navigation'
import {MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {NoEvents as NoEventsPresentation} from './no-events'

export interface NoEventsProps extends NoEventsRuntimeProps {}

interface NoEventsRuntimeProps {
  navigate: Function
  url: string
  message: string
  cta: string
  mobile: boolean
}

const mapRuntime = ({state}: MemberPageContext): NoEventsRuntimeProps => ({
  navigate: !isSite(state) ? () => navigateToPage(getNoUpcomingEventsPageId(state)) : null,
  url: isSite(state) ? getNoUpcomingEventsUrl(state) : null,
  message: getUserText(state, MembersTexts.noUpcomingEvents),
  cta: getUserText(state, MembersTexts.noUpcomingEventsLink),
  mobile: isMobile(state),
})

export const NoEvents = connect<{}, NoEventsRuntimeProps>(mapRuntime)(NoEventsPresentation)
